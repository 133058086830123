import React from "react";
import Chat from "./Chat";
import { useLayoutContext } from "../../contexts/LayoutContext";

const AIControl: React.FC = () => {
  const { chatWidth } = useLayoutContext();

  return (
    <div
      id="chat-panel"
      className={`pb-8 pt-4 px-6 h-full flex flex-col bg-white z-[10]`}
      style={{
        width: `${chatWidth}px`,
        maxWidth: `calc(100vw - 496px)`,
      }}
    >
      <Chat />
    </div>
  );
};

export default AIControl;
