import React from "react";

const BrainstormIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#09090B"
      {...props}
    >
      <path
        d="M7 4.5C6.46799 4.49985 5.94552 4.64117 5.48613 4.90948C5.02674 5.17778 4.64697 5.56343 4.38573 6.02687C4.12449 6.49032 3.9912 7.0149 3.9995 7.54684C4.00781 8.07877 4.15742 8.59894 4.433 9.054C3.74854 9.18625 3.13147 9.55267 2.68775 10.0903C2.24402 10.628 2.00133 11.3034 2.00133 12.0005C2.00133 12.6976 2.24402 13.373 2.68775 13.9107C3.13147 14.4483 3.74854 14.8147 4.433 14.947M7 4.5C7 3.83696 7.26339 3.20107 7.73223 2.73223C8.20107 2.26339 8.83695 2 9.5 2C10.163 2 10.7989 2.26339 11.2678 2.73223C11.7366 3.20107 12 3.83696 12 4.5M7 4.5C7 5.318 7.393 6.044 8 6.5M4.433 14.947C4.15767 15.402 4.00827 15.9221 4.0001 16.4539C3.99192 16.9857 4.12527 17.5101 4.38647 17.9734C4.64768 18.4367 5.02735 18.8222 5.4866 19.0904C5.94584 19.3587 6.46814 19.5 7 19.5C7 20.163 7.26339 20.7989 7.73223 21.2678C8.20107 21.7366 8.83695 22 9.5 22C10.163 22 10.7989 21.7366 11.2678 21.2678C11.7366 20.7989 12 20.163 12 19.5M4.433 14.947C4.79187 14.3529 5.34566 13.9016 6 13.67M12 4.5V19.5M12 4.5C12 3.83696 12.2634 3.20107 12.7322 2.73223C13.2011 2.26339 13.837 2 14.5 2C15.163 2 15.7989 2.26339 16.2678 2.73223C16.7366 3.20107 17 3.83696 17 4.5C17.532 4.49985 18.0545 4.64117 18.5139 4.90948C18.9732 5.17778 19.353 5.56343 19.6143 6.02687C19.8755 6.49032 20.0088 7.0149 20.0005 7.54684C19.9922 8.07877 19.8426 8.59894 19.567 9.054M12 19.5C12 20.163 12.2634 20.7989 12.7322 21.2678C13.2011 21.7366 13.837 22 14.5 22C15.163 22 15.7989 21.7366 16.2678 21.2678C16.7366 20.7989 17 20.163 17 19.5M17 19.5C17.5318 19.5 18.0542 19.3587 18.5134 19.0904C18.9726 18.8222 19.3523 18.4367 19.6135 17.9734C19.8747 17.5101 20.0081 16.9857 19.9999 16.4539C19.9917 15.9221 19.8423 15.402 19.567 14.947C20.2515 14.8147 20.8685 14.4483 21.3122 13.9107C21.756 13.373 21.9987 12.6976 21.9987 12.0005C21.9987 11.3034 21.756 10.628 21.3122 10.0903C20.8685 9.55267 20.2515 9.18625 19.567 9.054M17 19.5C17 18.682 16.607 17.956 16 17.5M19.567 9.054C19.2078 9.64738 18.654 10.0979 18 10.329"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BrainstormIcon;
