import React from "react";
import logo from "../assets/auth/logo.svg";
import ProfileComponent from "../Components/ProfileComponent";
import { useRagContext } from "../contexts/RagContext";
import ShareFeedbackModal from "../Components/ShareFeedbackModal/ShareFeedbackModal";
import SettingsModal from "../Components/SettingsModal";

const Header: React.FC = () => {
  const { openSettingsModal, handleCloseSettingsModal } = useRagContext();
  return (
    <>
      <div className="w-full h-[70px] flex justify-between items-center px-[18px] border-b border-solid border-[#D4D4D8] flex-none bg-white">
        <div className="flex gap-2 items-center overflow-hidden w-fit">
          <img
            src={logo}
            alt="Logo"
            className="w-[42px] h-[42px] rounded-full flex-none"
          />
          <div>
            <div className="text-xl leading-6 tracking-[-3%] text-[#27272A] font-medium font-Host-Grotesk">
              Profitable Signals
            </div>
            <div className="text-xs leading-[14px] tracking-[-2%] text-[#71717A] font-normal font-Host-Grotesk">
              Researcher
            </div>
          </div>
        </div>
        <ProfileComponent />
      </div>
      <ShareFeedbackModal />
      <SettingsModal
        open={openSettingsModal}
        handleClose={() => handleCloseSettingsModal()}
      />
    </>
  );
};

export default Header;
