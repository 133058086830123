import React, { useState, useEffect } from 'react'
import { useUserContext } from '../../contexts/UserContext'
import { toast } from 'react-toastify'
import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdOutlineAdd,
  MdClose,
  MdArrowBack,
} from 'react-icons/md'

import axios from 'axios'

const TeamManagement: React.FC = () => {
  const { user, isLoaded, isSignedIn } = useUserContext();
  const [userData, setUserData] = useState<any[]>([])
  const [isAddingUser, setIsAddingUser] = useState(false)
  const [isEditingUser, setIsEditingUser] = useState(false)
  const [isInviteStarted, setInviteStarted] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [addingUsers, setAddingUsers] = useState([
    {
      email: '',
      role: 'org:member',
      inviter_user_id: user?.id,
      team_id: user?.organizationMemberships[0]?.organization.id,
      team_name: user?.organizationMemberships[0]?.organization.name,
    },
  ])

  const dataFetch = async () => {
    try {
      const data = new FormData()
      data.append('team_id', user?.organizationMemberships[0]?.organization.id)
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getUsers',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      setUserData(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dataFetch()
  }, [])

  const handleDeleteClick = async (userData: any) => {
    try {
      const shouldDelete = window.confirm(
        'Are you sure you want to delete this user?',
      )
      if (shouldDelete) {
        if (userData['joined'] == false)
          userData['requesting_user_id'] = user?.id
        const result = await axios.post(
          process.env.REACT_APP_API_URL + 'api/deleteUser',
          userData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (result.status === 200) {
          toast.success('User Deleted!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: 'my_toast',
          })
          dataFetch()
        }
      }
    } catch (error) {

    }

  }

  const handleAddUser = () => {
    setAddingUsers((prevUsers) => [
      ...prevUsers,
      {
        email: '',
        role: 'org:member',
        inviter_user_id: user?.id,
        team_id: user?.organizationMemberships[0]?.organization.id,
        team_name: user?.organizationMemberships[0]?.organization.name,
      },
    ])
  }

  const handleChangeAddUserName = (value: string, index: number) => {
    setAddingUsers((prevUsers) =>
      prevUsers.map((user, idx) => {
        return {
          ...user,
        }
      }),
    )
  }

  const handleChangeAddUserEmail = (value: string, index: number) => {
    setAddingUsers((prevUsers) =>
      prevUsers.map((user, idx) => {
        return {
          ...user,
          email: idx === index ? value : user.email,
        }
      }),
    )
  }

  const handleInviteUsers = () => {
    const filteredUsers = addingUsers.filter(
      (user) => user.email !== ''
    );
    if (filteredUsers.length === 0) {
      toast.warning("Please input username and email correctly", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        toastId: 'my_toast',
      })
      return
    }
    setInviteStarted(true);
    axios
      .post(process.env.REACT_APP_API_URL + 'api/inviteUsers', filteredUsers)
      .then(function (response: any) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
        dataFetch()
        setAddingUsers([
          {
            email: '',
            role: 'org:member',
            inviter_user_id: user?.id,
            team_id: user?.organizationMemberships[0]?.organization.id,
            team_name: user?.organizationMemberships[0]?.organization.name,
          },
        ])
        setIsAddingUser(false)
        setInviteStarted(false);
      })
      .catch(function (error: any) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
      })

  }

  const handleUpdateUser = async () => {
    const data = new FormData()
    data.append('id', selectedUser?.id)
    data.append('role', selectedUser?.role)
    const result = await axios.post(
      process.env.REACT_APP_API_URL + 'api/editUser',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    setIsEditingUser(false)
    if (result.status === 200) {
      toast.success('User Edited!', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        toastId: 'my_toast',
      })
      dataFetch()
      setSelectedUser(null)
    }
  }

  return (
    <div className="pt-4 px-[148px] pb-16">
      <div className="w-full mb-4" hidden={isAddingUser || isEditingUser}>
        <div className="flex flex-col gap-2 mb-4">
          {userData.map((user, idx) => {
            return (
              <div
                className="flex flex-col gap-2 mb-4 cursor-pointer"
                key={idx}
              >
                {user?.users.map((userData: any, index: number) => {
                  return (
                    <div
                      className="w-full py-2 px-4 rounded-lg border border-[#E4E4E7] flex items-center gap-[10px]"
                      key={index}
                      onClick={() => {
                        // setIsEditingUser(true)
                        setSelectedUser(userData)
                      }}
                    >
                      <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#D4D4D8]">
                        <span className="text-base font-medium text-[#27272A] uppercase">
                          {userData?.user_name[0]}
                        </span>
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center gap-1">
                          <div className="text-sm leading-[22px] font-bold text-black">
                            {userData?.user_name}
                          </div>
                          {!userData?.joined && (
                            <div className="h-[22px] py-[2px] px-2 bg-[#DBEAFE] border border-[#3B82F6] rounded-full flex items-center justify-center">
                              <span className="text-[10px] leading-[18px] font-bold text-[#3B82F6]">
                                Invitation Sent
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="text-xs leading-5 font-medium text-[#71717A]">
                          {userData?.email}
                        </div>
                      </div>
                      <button
                        className="outline-none"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteClick(userData)
                        }}
                      >
                        <MdClose className="text-xl text-[#A1A1AA]" />
                      </button>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <button
          className="outline-none w-full h-12 border border-[#71717A] bg-transparent px-6 py-3 flex items-center justify-center gap-2 rounded-lg"
          onClick={() => setIsAddingUser(true)}
        >
          <MdOutlineAdd className="text-2xl text-[#71717A]" />
          <span className="text-base font-bold text-[#71717A]">
            Add Members
          </span>
        </button>
      </div>
      <div className="w-full" hidden={!isAddingUser}>
        <div className="w-full mb-4">
          <button
            className="outline-none flex gap-2 bg-transparent"
            onClick={() => setIsAddingUser(false)}
          >
            <MdArrowBack className="text-2xl flex-none text-[#71717A]" />
            <span className="text-base font-bold text-[#71717A]">Back</span>
          </button>
        </div>
        <div className="pl-1 text-base font-medium text-[#27272A] mb-2">
          Add Team Members
        </div>
        <div className="flex flex-col gap-2 mb-4">
          {addingUsers.map((user, index) => {
            return (
              <div className="flex gap-2" key={index}>
                <input
                  type="text"
                  value={user.email}
                  className="w-[100%] h-12 border border-[#A1A1AA] rounded-lg outline-none px-4 py-3 text-[#71717A] text-sm leading-6 font-mediuFm"
                  placeholder="Email Address"
                  onChange={(e) =>
                    handleChangeAddUserEmail(e.target.value, index)
                  }
                />
                <button
                  className="flex-none outline-none"
                  onClick={() =>
                    setAddingUsers((prevUsers) =>
                      prevUsers.filter((e, idx) => idx !== index),
                    )
                  }
                  disabled={index === 0}
                >
                  <MdClose className="text-2xl flex-none text-[#71717A]" />
                </button>
              </div>
            )
          })}
          <button
            className="outline-none w-full h-12 border border-[#71717A] bg-transparent px-6 py-3 flex items-center justify-center gap-2 rounded-lg"
            onClick={() => handleAddUser()}
          >
            <MdOutlineAdd className="text-2xl text-[#71717A]" />
            <span className="text-base font-bold text-[#71717A]">
              Add Member
            </span>
          </button>
        </div>
        <button
          className={`w-full h-12 flex items-center justify-center rounded-lg cursor-pointer ${isInviteStarted ? 'bg-[#E4E4E7]' : 'bg-[#3B82F6]'
            } outline-none`}
          onClick={() => handleInviteUsers()}
        >
          <span
            className={`text-base font-bold ${isInviteStarted ? 'text-[#A1A1AA]' : 'text-white'
              }`}
          >
            {isInviteStarted ? 'Inviting...' : 'Invite'}
          </span>
        </button>
      </div>
      <div className="w-full" hidden={!isEditingUser}>
        <div className="w-full mb-4">
          <button
            className="outline-none flex gap-2 bg-transparent"
            onClick={() => setIsEditingUser(false)}
          >
            <MdArrowBack className="text-2xl flex-none text-[#71717A]" />
            <span className="text-base font-bold text-[#71717A]">Back</span>
          </button>
        </div>
        <div className="pl-1 text-base font-medium text-[#27272A] mb-2">
          Edit Team Member
        </div>
        <div className="flex flex-col gap-2 mb-4">
          <div className="flex gap-2">
            <input
              type="text"
              value={selectedUser?.email}
              className="w-[100%] h-12 border border-[#A1A1AA] rounded-lg outline-none px-4 py-3 text-[#71717A] text-sm leading-6 font-mediuFm"
              placeholder="Email Address"
              onChange={(e) =>
                setSelectedUser((prevUser: any) => {
                  return { ...prevUser, email: e.target.value }
                })
              }
            />
          </div>
        </div>
        <button
          className="w-full h-12 flex items-center justify-center rounded-lg cursor-pointer bg-[#3B82F6] outline-none"
          onClick={() => handleUpdateUser()}
        >
          <span className="text-base font-bold text-white">Update</span>
        </button>
      </div>
    </div>
  )
}

export default TeamManagement
