import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import BrainstormIcon from "../../Components/icons/BrainstormIcon";
import ArrowRightIcon from "../../Components/icons/ArrowRightIcon";
import ConceptIcon from "../../Components/icons/ConceptIcon";
import { BreadCrumbMenu } from "../../Components/common/BreadCrumb";
import { useLayoutContext } from "../../contexts/LayoutContext";

type HomeComponentProps = RouteComponentProps;
const Home: React.FC<HomeComponentProps> = ({ history }) => {
  const { setBreadCrumbs } = useLayoutContext();

  useEffect(() => {
    const menus: BreadCrumbMenu[] = [
      {
        title: "Home",
        active: true,
        onClick: () => {},
      },
    ];
    setBreadCrumbs(menus);
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center gap-10 p-10">
      <div className="">
        <div className="text-4xl font-medium text-[#111927] mb-10 text-center">
          Venture diligence for internal innovation.
        </div>
        <div className="flex justify-center px-2 gap-3">
          <Link
            to="/research"
            className="w-[370px] bg-[#3b83f60c] rounded-[32px]"
          >
            <div className="flex flex-col gap-4 p-6">
              <BrainstormIcon className="w-6 h-6" stroke="#3B82F6" />
              <div className="text-black text-2xl font-medium">Research</div>
              <div className="text-[#52525B] text-base font-normal">
                Analyze your docs & source material Discover scholarly articles
                & publications Explore the patent and IP landscape Search the
                entire web for evidence
              </div>
              <div className="flex justify-end">
                <button className="w-8 h-8 rounded-lg bg-white border border-[#E5E7EB] flex items-center justify-center">
                  <ArrowRightIcon className="w-6 h-6" stroke="#3B82F6" />
                </button>
              </div>
            </div>
          </Link>
          <Link
            to="/concept"
            className="w-[370px] bg-[#3b83f60c] rounded-[32px]"
          >
            <div className="flex flex-col gap-4 p-6">
              <ConceptIcon className="w-6 h-6" stroke="#3B82F6" />
              <div className="text-black text-2xl font-medium">Evaluate</div>
              <div className="text-[#52525B] text-base font-normal">
                Generate venture concepts from data Define initial pitch &
                business model Assess market size and opportunities Uncover
                potential buyers & suppliers
              </div>
              <div className="flex justify-end">
                <button className="w-8 h-8 rounded-lg bg-white border border-[#E5E7EB] flex items-center justify-center">
                  <ArrowRightIcon className="w-6 h-6" stroke="#3B82F6" />
                </button>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
