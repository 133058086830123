import React, { useEffect, useState } from "react";
import { useRagContext } from "../../contexts/RagContext";
import { BreadCrumbMenu } from "../../Components/common/BreadCrumb";
import { BsThreeDotsVertical } from "react-icons/bs";
import TrashIcon from "../../Components/icons/TrashIcon";
import ArrowFolderIcon from "../../Components/icons/ArrowFolderIcon";
import { Menu, MenuItem, MenuProps, styled } from "@mui/material";
import FolderCheckedIcon from "../../Components/icons/FolderCheckedIcon";
import EditIcon from "../../Components/icons/EditIcon";
import { GrFormNext } from "react-icons/gr";
import DeleteFolderModal from "../../Components/Concept/DeleteFolderModal/DeleteFolderModal";
import DeleteConceptModal from "../../Components/Concept/DeleteConceptModal/DeleteConceptModal";
import EditConceptModal from "../../Components/Concept/EditConceptModal/EditConceptModal";
import RenameFolderModal from "../../Components/Concept/RenameFolderModal/RenameFolderModal";
import AddFolderModal from "../../Components/Concept/AddFolderModal/AddFolderModal";
import { GoPlus } from "react-icons/go";
import DoubleCheckIcon from "../../Components/icons/DoubleCheckIcon";
import BpCheckbox from "../../Components/common/BPCheckbox";
import axios from "axios";
import { useHistory } from "react-router";
import ConceptModal from "../../Components/Concept/ConceptModal/ConceptModal";
import BrainStormModal from "../../Components/Concepts/BrainStormModal/BrainStormModal";
import StartBrainstormDrawer from "../../Components/Concepts/StartBrainstormDrawer/StartBrainstormDrawer";
import CreateConceptDrawer from "../../Components/Concepts/createConceptDrawer/createConceptDrawer";
import ConceptSelectFolderModal from "../../Components/Concept/ConceptModal/SelectFolderModal/SelectFolderModal";
import { useLayoutContext } from "../../contexts/LayoutContext";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 14,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "6px",
    },
    "& .MuiMenuItem-root": {
      padding: "10px 12px",
      gap: "12px",
      width: "224px",
      borderRadius: "12px",
    },
  },
}));

const Concept: React.FC = () => {
  const history = useHistory();
  const {
    conceptFolders,
    setting,
    getConceptFolders,
    openCreateConceptDrawer,
    openStartBrainstormDrawer,
  } = useRagContext();
  const { setBreadCrumbs } = useLayoutContext();
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<any>("");
  const [selectedConcept, setSelectedConcept] = useState<any>(null);
  const [selectedConceptId, setSelectedConcepId] = useState("");
  const [folderAnchorEl, setFolderAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const folderMenuOpen = Boolean(folderAnchorEl);

  const [fileAnchorEl, setFileAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const fileMenuOpen = Boolean(fileAnchorEl);

  const [fileMoveAnchorEl, setFileMoveAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const fileMoveMenuOpen = Boolean(fileMoveAnchorEl);

  const [fileMoveAllAnchorEl, setFileMoveAllAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const fileMoveAllMenuOpen = Boolean(fileMoveAllAnchorEl);

  const [deleteFolderModalOpen, setDeleteFolderModalOpen] =
    React.useState<boolean>(false);

  const [deleteConceptModalOpen, setDeleteConceptModalOpen] =
    React.useState<boolean>(false);

  const [editConceptModalOpen, setEditConceptModalOpen] =
    React.useState<boolean>(false);

  const [renameFolderModalOpen, setRenameFolderModalOpen] =
    React.useState<boolean>(false);

  const [addFolderModalOpen, setAddFolderModalOpen] =
    React.useState<boolean>(false);

  const [selectedConceptList, setSelectedConceptList] = useState<any[]>([]);

  const [deleteConceptList, setDeleteConceptList] = useState<string[]>([]);

  useEffect(() => {
    const menus: BreadCrumbMenu[] = [
      {
        title: "Concept",
        active: true,
        onClick: () => {
          history.push({ pathname: "/concept" });
        },
      },
    ];
    setBreadCrumbs(menus);
  }, []);

  const handleFolderMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFolderAnchorEl(event.currentTarget);
  };
  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null);
    setSelectedFolderId("");
  };

  const handleFileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFileAnchorEl(event.currentTarget);
  };

  const handleFileMenuClose = () => {
    setFileAnchorEl(null);
    setSelectedConcepId("");
  };

  const handleFileMoveMenuClick = (event: any) => {
    setFileMoveAnchorEl(event.currentTarget);
  };

  const handleFileMoveMenuClose = () => {
    setFileMoveAnchorEl(null);
  };

  const handleFileMoveAllMenuClick = (event: any) => {
    setFileMoveAllAnchorEl(event.currentTarget);
  };

  const handleFileAllMoveMenuClose = () => {
    setFileMoveAllAnchorEl(null);
  };

  const handleToggleConceptSelect = (concept_id: string) => {
    if (selectedConceptList.find((e) => e === concept_id)) {
      setSelectedConceptList((prev: any) => {
        return prev.filter((e: string) => e !== concept_id);
      });
    } else {
      setSelectedConceptList((prev: any) => {
        return [...prev, concept_id];
      });
    }
  };

  const isSelectedConceptFolder = (folder_id: string): boolean => {
    let currentFolder = conceptFolders.find((e) => e.id === folder_id);
    let isSelected = false;
    currentFolder.concepts.forEach((concept: any) => {
      if (selectedConceptList.find((e) => e === concept.id)) {
        isSelected = true;
      }
    });
    return isSelected;
  };

  const isSelectedConcept = (concept_id: string): boolean => {
    return selectedConceptList.find((id) => id === concept_id);
  };

  const handleToggleConceptFolder = (folder_id: string) => {
    let tempSelectedConceptList = selectedConceptList;
    let currentFolder = conceptFolders.find((e) => e.id === folder_id);
    if (isSelectedConceptFolder(folder_id)) {
      setSelectedConceptList(
        tempSelectedConceptList.filter((id) =>
          currentFolder.concepts.includes((concept: any) => concept.id === id)
        )
      );
    } else {
      currentFolder.concepts.forEach((concept: any) => {
        if (!tempSelectedConceptList.find((id) => id === concept.id)) {
          tempSelectedConceptList.push(concept.id);
        }
      });
      setSelectedConceptList(tempSelectedConceptList);
    }
  };

  const handleMoveConcept = async (folder_id: string) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/moveConcept",
        {
          concept_id_list: [selectedConcept.id],
          target_concept_folder_id: folder_id,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          getConceptFolders();
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMoveAllConcept = async (folder_id: string) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/moveConcept",
        {
          concept_id_list: selectedConceptList,
          target_concept_folder_id: folder_id,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          setSelectedConceptList([]);
          getConceptFolders();
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const openConceptModal = (concept: any) => {
    const searchParams = new URLSearchParams();
    searchParams.set("render_type", "modal");
    searchParams.set("render_modal", "concept_modal");
    searchParams.set("concept_id", concept.id);

    history.push({
      pathname: "/concept", // you can change the path here
      search: `?${searchParams.toString()}`, // append your query parameters here
    });
  };

  return (
    <div className="w-full h-full absolute top-0 left-0 z-[1000] bg-white">
      <div className="px-10 py-3 flex justify-between">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-2">
            {selectedConceptList.length === 0 && (
              <button
                className={`h-12 rounded-xl outline-none flex items-center justify-center gap-2 px-6 py-3 w-fit ${setting?.verified ? 'bg-[#3B82F6]' : 'bg-gray-400'}`}
                onClick={() => setAddFolderModalOpen(true)}
                disabled={!setting?.verified}
              >
                <GoPlus className="w-6 h-6 text-white" />
                <span className="text-white text-base font-bold font-Satoshi">
                  Add Folder
                </span>
              </button>
            )}
            {selectedConceptList.length > 0 && (
              <>
                <button
                  className="h-12 rounded-xl border border-solid border-[#EF4444] outline-none flex items-center justify-center gap-2 px-6 py-3 w-fit"
                  onClick={() => {
                    setDeleteConceptList(selectedConceptList);
                    setDeleteConceptModalOpen(true);
                  }}
                >
                  <TrashIcon className="w-6 h-6 flex-none" />
                  <span className="text-[#EF4444] text-base font-bold font-Satoshi">
                    Delete All
                  </span>
                </button>
                <button
                  className="h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2 px-6 py-3 w-fit"
                  onClick={(e) => handleFileMoveAllMenuClick(e)}
                >
                  <ArrowFolderIcon className="w-6 h-6" />
                  <span className="text-white text-base font-bold font-Satoshi">
                    Move To
                  </span>
                </button>
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <button
              className={`h-12 rounded-xl outline-none flex items-center justify-center gap-2 px-6 py-3 w-fit ${setting?.verified ? 'bg-[#3B82F6]' : 'bg-gray-400'}`}
              onClick={() => openCreateConceptDrawer()}
              disabled={!setting?.verified}
            >
              <span className="text-white text-base font-bold font-Satoshi">
                Create New Concept
              </span>
            </button>
            <button
              className={`h-12 rounded-xl outline-none flex items-center justify-center gap-2 px-6 py-3 w-fit ${setting?.verified ? 'bg-[#3B82F6]' : 'bg-gray-400'}`}
              onClick={() => openStartBrainstormDrawer()}
              disabled={!setting?.verified}
            >
              <span className="text-white text-base font-bold font-Satoshi">
                Start Brainstorm
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="px-10 py-2 relative h-[calc(100vh-190px)]">
        <div className="flex gap-4 h-full w-full overflow-x-auto">
          {conceptFolders.map((folder: any, index: number) => (
            <div
              className="w-[420px] flex-none rounded-[10px] overflow-hidden shadow-md border border-solid border-[#E4E4E7] bg-white"
              key={index}
            >
              <div
                className={`h-[58px] flex items-center justify-center flex-none border-b border-solid border-[#E4E4E7] relative group mb-1 ${
                  isSelectedConceptFolder(folder.id)
                    ? "bg-[#FAFAFA]"
                    : selectedFolderId === folder.id
                    ? "bg-[#DBEAFE]"
                    : "bg-[#FAFAFA] hover:bg-[#DBEAFE]"
                }`}
              >
                <span className="text-lg font-medium leading-[26px] text-[#09090B]">
                  {folder.folder_name}
                </span>
                {isSelectedConceptFolder(folder.id) ? (
                  <button
                    className={`absolute top-1/2 right-3 -translate-y-1/2 w-6 h-6`}
                    onClick={(e) => {
                      handleToggleConceptFolder(folder.id);
                    }}
                  >
                    <DoubleCheckIcon className="w-6 h-6" />
                  </button>
                ) : (
                  <button
                    className={`absolute top-1/2 right-3 -translate-y-1/2 w-6 h-6 ${
                      selectedFolderId === folder.id
                        ? "block"
                        : "group-hover:block hidden"
                    }`}
                    onClick={(e) => {
                      setSelectedFolder(folder);
                      setSelectedFolderId(folder.id);
                      handleFolderMenuClick(e);
                    }}
                  >
                    <BsThreeDotsVertical className="text-[#71717A] text-xl" />
                  </button>
                )}
              </div>
              <div className="p-4 flex flex-col gap-[10px] h-[calc(100vh-280px)] overflow-y-auto">
                {folder.concepts.map((concept: any, cIndex: number) => (
                  <div
                    className="rounded-[10px] shadow-md border border-solid border-[#E4E4E7] p-4 group"
                    key={cIndex}
                  >
                    <div className="flex items-center justify-between mih-h-6 h-fit">
                      <div
                        className="text-sm leading-[22px] font-medium text-[#09090B] cursor-pointer w-fit"
                        onClick={() => openConceptModal(concept)}
                      >
                        {concept.concept_title}
                      </div>
                      {isSelectedConceptFolder(folder.id) ? (
                        <BpCheckbox
                          size="small"
                          className="w-[14px] h-[14px]"
                          checked={isSelectedConcept(concept.id)}
                          onClick={() => handleToggleConceptSelect(concept.id)}
                        ></BpCheckbox>
                      ) : (
                        <button
                          className={`w-6 h-6 ${
                            selectedConceptId === concept.id
                              ? "block"
                              : "group-hover:block hidden"
                          }`}
                          onClick={(e) => {
                            handleFileMenuClick(e);
                            setSelectedConcept(concept);
                            setSelectedConcepId(concept.id);
                          }}
                        >
                          <BsThreeDotsVertical className="text-[#71717A] text-xl" />
                        </button>
                      )}
                    </div>
                    <div className="text-xs leading-5 font-normal text-[#71717A]">
                      {concept.concept_description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <StyledMenu
        id="folder-menu"
        anchorEl={folderAnchorEl}
        open={folderMenuOpen}
        onClose={handleFolderMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleFolderMenuClose();
            handleToggleConceptFolder(selectedFolder.id);
          }}
          disableRipple
        >
          <FolderCheckedIcon className="w-6 h-6" />
          <span className="text-[#3F3F46] text-base font-medium">
            Select all
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFolderMenuClose();
            setRenameFolderModalOpen(true);
          }}
          disableRipple
        >
          <EditIcon className="w-6 h-6" />
          <span className="text-[#3F3F46] text-base font-medium">Rename</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFolderMenuClose();
            setDeleteFolderModalOpen(true);
          }}
          disableRipple
        >
          <TrashIcon className="w-6 h-6" />
          <span className="text-[#EF4444] text-base font-medium">Delete</span>
        </MenuItem>
      </StyledMenu>

      <StyledMenu
        id="file-menu"
        anchorEl={fileAnchorEl}
        open={fileMenuOpen}
        onClose={handleFileMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleFileMoveMenuClick} disableRipple>
          <ArrowFolderIcon className="w-6 h-6" stroke="#09090B" />
          <span className="text-[#3F3F46] text-base font-medium flex-1">
            Move to
          </span>
          <GrFormNext className="w-6 h-6 flex-none text-[#A1A1AA]" />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleFileMenuClose();
            if (selectedConcept) handleToggleConceptSelect(selectedConcept.id);
          }}
          disableRipple
        >
          <FolderCheckedIcon className="w-6 h-6" />
          <span className="text-[#3F3F46] text-base font-medium">Select</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFileMenuClose();
            openConceptModal(selectedConcept);
          }}
          disableRipple
        >
          <EditIcon className="w-6 h-6" />
          <span className="text-[#3F3F46] text-base font-medium">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFileMenuClose();
            setDeleteConceptList([selectedConcept.id]);
            setDeleteConceptModalOpen(true);
          }}
          disableRipple
        >
          <TrashIcon className="w-6 h-6" />
          <span className="text-[#EF4444] text-base font-medium">Delete</span>
        </MenuItem>
      </StyledMenu>

      <StyledMenu
        id="file-move-menu"
        anchorEl={fileMoveAnchorEl}
        open={fileMoveMenuOpen}
        onClose={handleFileMoveMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {conceptFolders
          .filter(
            (folder: any) =>
              !folder.concepts.find(
                (concept: any) => concept.id === selectedConcept?.id
              )
          )
          .map((folder: any, index: number) => (
            <MenuItem
              onClick={() => {
                handleFileMenuClose();
                handleFileMoveMenuClose();
                handleMoveConcept(folder.id);
              }}
              className="!min-w-[224px] !w-fit !max-w-[300px]"
              key={index}
              disableRipple
            >
              <span
                className="text-[#3F3F46] text-base font-medium flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis"
                title={folder.folder_name}
              >
                {folder.folder_name}
              </span>
            </MenuItem>
          ))}
      </StyledMenu>

      <StyledMenu
        id="file-move-all-menu"
        anchorEl={fileMoveAllAnchorEl}
        open={fileMoveAllMenuOpen}
        onClose={handleFileAllMoveMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {conceptFolders.map((folder: any, index: number) => (
          <MenuItem
            onClick={() => {
              handleFileAllMoveMenuClose();
              handleMoveAllConcept(folder.id);
            }}
            key={index}
            className="!min-w-[224px] !w-fit !max-w-[300px]"
            disableRipple
          >
            <span
              className="text-[#3F3F46] text-base font-medium flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis"
              title={folder.folder_name}
            >
              {folder.folder_name}
            </span>
          </MenuItem>
        ))}
      </StyledMenu>
      <DeleteFolderModal
        open={deleteFolderModalOpen}
        folder={selectedFolder}
        handleClose={() => setDeleteFolderModalOpen(false)}
      />
      <DeleteConceptModal
        conceptIds={deleteConceptList}
        open={deleteConceptModalOpen}
        handleClose={() => setDeleteConceptModalOpen(false)}
        onDelete={(list) => {
          setSelectedConceptList((prev: string[]) => {
            return prev.filter((e) => !list.includes(e));
          });
        }}
      />
      <EditConceptModal
        open={editConceptModalOpen}
        concept={selectedConcept}
        handleClose={() => setEditConceptModalOpen(false)}
      />
      <RenameFolderModal
        open={renameFolderModalOpen}
        folder={selectedFolder}
        handleClose={() => setRenameFolderModalOpen(false)}
      />
      <AddFolderModal
        open={addFolderModalOpen}
        handleClose={() => setAddFolderModalOpen(false)}
      />
      <ConceptModal />
      <BrainStormModal />
      <StartBrainstormDrawer />
      <CreateConceptDrawer />
      <ConceptSelectFolderModal />
    </div>
  );
};

export default Concept;
