import React, { useEffect, useState, useCallback } from "react";
import "../../App.css";
import $ from "jquery";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import {
  Toolbar as PDFToolbar,
  PdfViewerComponent,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import {
  showSpinner,
  hideSpinner,
  createSpinner,
} from "@syncfusion/ej2-popups";
import { getExtension } from "../../utils";
import { useUserContext } from "../../contexts/UserContext";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { useRagContext } from "../../contexts/RagContext";

DocumentEditorContainerComponent.Inject(Toolbar);

export const Editor: React.FC = () => {
  const [editorName, setEditorName] = useState("docx");
  const [pdfURL, setPDFURL] = useState("");
  const spreadsheetRef = React.useRef<SpreadsheetComponent>(null);
  const { user } = useUserContext();
  const { isSidebarDragging, isChatDragging, sidebarWidth, chatWidth } =
    useLayoutContext();
  const { openedDocument, documentUpdated, selectedFolderID, pageNumber } =
    useRagContext();

  const handleResize = useCallback(() => {
    let widthForEditor = window.innerWidth - sidebarWidth - chatWidth - 10;
    if (widthForEditor < 20) widthForEditor = 0;
    let editor = document.getElementById("editor-panel");
    if (editor) editor.style.width = widthForEditor + "px";

    setTimeout(() => {
      let pdfviewerObject: any = $("#container");
      let pdfViewerContainer = pdfviewerObject[0]?.ej2_instances[0];
      let documentObject: any = $("#docx_container");
      let documentContainer: any =
        documentObject[0]?.ej2_instances[0]?.documentEditor;
      if (documentContainer) {
        documentContainer.resize();
      }
      if (pdfViewerContainer) {
        if (pdfViewerContainer?.isRendered) {
          pdfViewerContainer.updateViewerContainer();
        }
      }
    }, 0);
  }, [sidebarWidth, chatWidth]);

  useEffect(() => {
    const container = $("#docx_container");
    if (container) {
      createSpinner({
        // Specify the target for the spinner to show
        target: container[0],
      });
    }
  }, [editorName]);

  useEffect(() => {
    if (!isSidebarDragging && !isChatDragging) {
      setTimeout(() => {
        handleResize();
      }, 0);
    }
  }, [isSidebarDragging, isChatDragging, sidebarWidth, chatWidth]);

  useEffect(() => {
    window.removeEventListener("resize", handleResize);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (openedDocument) {
      if (getExtension(openedDocument) === "docx") {
        setEditorName("docx");
        let documentObject: any = $("#docx_container");
        if (documentObject) {
          showSpinner(documentObject[0]);
        }
        try {
          var xhr = new XMLHttpRequest();
          xhr.open(
            "GET",
            process.env.REACT_APP_API_URL +
              "upload/" +
              user?.organizationMemberships[0]?.organization.id +
              "/" +
              selectedFolderID +
              "/" +
              openedDocument,
            true
          );
          xhr.responseType = "blob";
          xhr.onload = function (e) {
            if (this.status === 200) {
              let file = new File([this.response], "document");
              let httpRequest: XMLHttpRequest = new XMLHttpRequest();
              httpRequest.open(
                "POST",
                "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/import",
                true
              );
              httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === 4) {
                  if (
                    httpRequest.status === 200 ||
                    httpRequest.status === 304
                  ) {
                    documentObject = $("#docx_container");
                    if (documentObject) {
                      setTimeout(() => {
                        documentObject[0].ej2_instances[0].documentEditor.open(
                          httpRequest.responseText
                        );
                      }, 10);
                    }
                    setInterval(function () {
                      hideSpinner(documentObject[0]);
                    }, 500);
                    let documentViewer =
                      documentObject[0]?.ej2_instances?.[0]?.documentEditor;
                    if (documentViewer) {
                      setTimeout(() => {
                        documentViewer.scrollToPage(pageNumber);
                      }, 1000);
                    }
                  } else {
                    console.error(httpRequest.response);
                  }
                }
              };
              let formData: FormData = new FormData();
              formData.append("files", file);
              httpRequest.send(formData);
            }
          };
          xhr.send();
        } catch (error) {}
      } else if (getExtension(openedDocument) === "xlsx") {
        setEditorName("xlsx");
        const fetchExcelFile = async () => {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              "upload/" +
              user?.organizationMemberships[0]?.organization.id +
              "/" +
              selectedFolderID +
              "/" +
              openedDocument
          ); // fetch the remote url
          const fileBlob = await response.blob(); // convert the excel file to blob
          const file = new File([fileBlob], openedDocument); //convert the blob into file
          let spreadsheet = spreadsheetRef.current;
          if (spreadsheet) {
            spreadsheet.open({ file }); // open the file into Spreadsheet
          }
        };
        fetchExcelFile();
      } else {
        try {
          setEditorName("pdf");
          setPDFURL(
            process.env.REACT_APP_API_URL +
              "upload/" +
              user?.organizationMemberships[0]?.organization.id +
              "/" +
              selectedFolderID +
              "/" +
              openedDocument
          );
        } catch (error) {}
      }
    }
  }, [openedDocument, documentUpdated]);

  const openCompleteHandler = () => {
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet) {
      spreadsheet.activeSheetIndex = pageNumber > 0 ? pageNumber - 1 : 0;
    }
  };
  
  return (
    <div className="bg-main_bg relative">
      {editorName === "pdf" ? (
        <PdfViewerComponent
          id="container"
          documentPath={pdfURL}
          resourceUrl="https://cdn.syncfusion.com/ej2/25.1.40/dist/ej2-pdfviewer-lib"
          initialRenderPages={5}
          documentLoad={() => {
            let pdfviewerObject: any = $("#container");
            let pdfViewerContainer = pdfviewerObject[0]?.ej2_instances[0];

            if (pdfViewerContainer) {
              if (pdfViewerContainer?.isRendered) {
                setTimeout(() => {
                  if (pdfviewerObject[0].ej2_instances[0]) {
                    pdfviewerObject[0].ej2_instances[0].navigation.goToPage(
                      pageNumber
                    );
                  }
                }, 500);
              }
            }
          }}
          style={{ height: "calc(100vh - 124px)", minHeight: 'unset !important' }}
          toolbarSettings={{
            toolbarItems: ["PageNavigationTool"],
          }}
        >
          <Inject
            services={[
              Magnification,
              Navigation,
              Annotation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              FormFields,
              FormDesigner,
              PDFToolbar,
            ]}
          />
        </PdfViewerComponent>
      ) : editorName === "xlsx" ? (
        <SpreadsheetComponent
          ref={spreadsheetRef}
          openUrl="https://services.syncfusion.com/react/production/api/spreadsheet/open"
          height={"calc(100vh - 124px)"}
          openComplete={openCompleteHandler}
          id="xlsx_container"
          showRibbon={false}
          showFormulaBar={false}
        />
      ) : editorName === "docx" ? (
        <DocumentEditorContainerComponent
          id="docx_container"
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          enableToolbar={false}
          height={"calc(100vh - 124px)"}
          showPropertiesPane={false}
        />
      ) : (
        <>Unsupported file type</>
      )}
    </div>
  );
};
